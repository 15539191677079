
tag-input{
  -webkit-transition: all 300ms linear;
  -moz-transition: all 300ms linear;
  -o-transition: all 300ms linear;
  -ms-transition: all 300ms linear;
  transition: all 300ms linear;
  height: 100%;
  overflow-y: auto;
  text-align: left;
  .ng2-tag-input {
    border: none !important;
    .ng2-tags-container {
      .ng2-tag-input__text-input{
        height: 32px;
      }
      & > tag{
        background-color: $transparent-bg;
        cursor: pointer;
        overflow: hidden;
        position: relative;
        margin: 5px 3px 5px 0;
        padding: 2px 12px;
        border: 1px solid #888888;
        border-radius: 3px;
        color: #888888;
        font-weight: 500;
        font-size: 12px;
        text-transform: uppercase;
        display: inline-block;
        vertical-align: middle;
        height: unset;
        line-height: 1.5;
        letter-spacing: unset;
        .tag__text{
          line-height: 1.5;
        }
        &:hover,
        &:active,
        &:focus{
          background-color: $transparent-bg !important;
          box-shadow: none !important;
          color: #888888 !important;
          delete-icon{
            opacity: 1;
            padding-right: 2px;
          }
        }
        delete-icon{
          height: unset;
          width: unset;
          cursor: pointer;
          font-size: 12px;
          padding: 2px 0;
          position: absolute;
          right: 0;
          opacity: 0;
          text-align: right;
          text-decoration: none;
          top: 0;
          width: 100%;
          z-index: 2;

          @include transition(60ms, $transition-ease-in);
          span{
            display: none;
          }
          &:before{
            content: "\f00d";
            font-family: "FontAwesome";
          }
          &:hover{
            transform: none;
          }
        }
        tag-ripple{
          display: none;
        }
      }
    }

  }
  &.tags-azure{
    .ng2-tag-input .ng2-tags-container > tag{
      border-color: $info-color;
      &,
      &:hover,
      &:active,
      &:focus{
        color: $info-color !important;
      }
    }
    &.tags-fill .ng2-tag-input .ng2-tags-container > tag{
      &,
      &:hover,
      &:active,
      &:focus{
        color: $white-color !important;
        background-color: $info-color !important;
      }
    }
  }
  &.tags-blue{
    .ng2-tag-input .ng2-tags-container > tag{
      border-color: $primary-color;
      &,
      &:hover,
      &:active,
      &:focus{
        color: $primary-color !important;
      }
    }
    &.tags-fill .ng2-tag-input .ng2-tags-container > tag{
      &,
      &:hover,
      &:active,
      &:focus{
        color: $white-color !important;
        background-color: $primary-color !important;
      }
    }
  }
  &.tags-green{
    .ng2-tag-input .ng2-tags-container > tag{
      border-color: $success-color;
      &,
      &:hover,
      &:active,
      &:focus{
        color: $success-color !important;
      }
    }
    &.tags-fill .ng2-tag-input .ng2-tags-container > tag{
      &,
      &:hover,
      &:active,
      &:focus{
        color: $white-color !important;
        background-color: $success-color !important;
      }
    }
  }
  &.tags-orange{
    .ng2-tag-input .ng2-tags-container > tag{
      border-color: $warning-color;
      &,
      &:hover,
      &:active,
      &:focus{
        color: $warning-color !important;
      }
    }
    &.tags-fill .ng2-tag-input .ng2-tags-container > tag{
      &,
      &:hover,
      &:active,
      &:focus{
        color: $white-color !important;
        background-color: $warning-color !important;
      }
    }
  }

  &.tags-red{
    .ng2-tag-input .ng2-tags-container > tag{
      border-color: $danger-color;
      &,
      &:hover,
      &:active,
      &:focus{
        color: $danger-color !important;
      }
    }
  }
  &.tags-fill .ng2-tag-input .ng2-tags-container > tag{
    &,
    &:hover,
    &:active,
    &:focus{
      color: $white-color !important;
      background-color: $danger-color !important;
    }
  }
}
